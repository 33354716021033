import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

type ValidationStatus = "validated" | "notvalid" | "sended" | "exceeded" | "timeout" | "resend"

export function sendDataLayerPush(status: ValidationStatus) {
  switch (status) {
    case "validated":
      pushToDataLayer({
        event: "smsverification",
        result: "success",
      })
      break
    case "notvalid":
      pushToDataLayer({ event: "smsverification", result: "fail" })
      break
    case "exceeded":
      pushToDataLayer({ event: "smsverification", result: "exceeded" })
      break
    case "timeout":
      pushToDataLayer({
        event: "smsverification",
        result: "timeout",
      })
      break
    case "sended": // custom status (not came from backend)
      pushToDataLayer({
        event: "smsverification",
        result: "sended",
      })
      break
    case "resend": // custom status (not came from backend)
      pushToDataLayer({
        event: "smsverification",
        result: "resend",
      })
      break
    default:
      break
  }
}
