import React, { useState } from "react"

import { CustomLogo } from "../components/Headers/CustomLogo"
import { BlackHalvaLogo } from "../components/Headers/Logos"
import Layout from "../components/Layouts/secondaryPage"
import FormContainer from "../components/FormContainer"
import { NewVerifySMS } from "../components/NewVerifySMS"
import ProgressBarGreen from "../components/ProgressBarGreen"

export default function VerifyPage() {
  const [widthGreenBar, setWidthGreenBar] = useState(50)

  return (
    <Layout>
      <CustomLogo withButton={false} Logo={BlackHalvaLogo} />

      <FormContainer>
        <ProgressBarGreen steps={2} widthAnimationBar={90} widthGreenBar={widthGreenBar} />
        <NewVerifySMS setWidthGreenBar={setWidthGreenBar} />
      </FormContainer>
    </Layout>
  )
}
