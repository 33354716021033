import React from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"

import * as styles from "./formContainer.module.scss"

type Props = {
  className?: string
  children: React.ReactNode
}
export default function FormContainer({ className, ...props }: Props) {
  return <Container {...props} className={clsx(styles.container, className)} />
}
